@import "~bootstrap/scss/bootstrap";

body {
    margin: 0;
    padding: 0;
    font-family: Helvetica, Arial, sans-serif;
    //background-image: url("~/public/bg-zdesign.svg");
    background: #ebf0ee;
}

#cookiebanner{
    position: fixed;
    bottom: 0;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 200px;
    width: 300px;
    background: #ebf0ee;
    padding: 20px;
}

a:visited{
    color:#882948;
}

header{
    
    display: flex;
    .container{
        a{
            display: flex;
            img{
                margin: 0 auto;
                
            }
        }
    nav{
        width: fit-content;
        margin: 0 auto;
        ul{
            width: fit-content;
            margin: 0 0 15px 0;
            li{
                display: inline-block;
                a{
                    color:#882948;
                    text-decoration: none;
                    font-size: 1.5em;
                    padding: 0 18px;
                }
                a:hover{
                    color: #9cbaea;
                }
                
            }
            li.active{
                a{
                    color: #9cbaea;
                    font-weight: 700;
                }
            }
        }
    }
    form{
        margin: 30px 0 0 30px;
    }
}
}

main{
    
    background: #fff;
}

.startpage, #carouselExampleIndicators{
    padding: 40px 0 20px 0;
}

.startpage{
    width: 100%;
}


#new2{
    background: #ccc;
}

footer{
    position: fixed;
    bottom: 0px;
    padding: 10px;
    background: #ebf0ee;
    width: 100%;
    z-index: 10000;
    ul{
        display: flex;
        list-style: none;
        padding: 0;
        li{
            padding: 0 10px;
        }
    }
}

.block{
    padding: 20px 0;
}

@media (max-width: 998px){
    header{
        .container{
            nav{
                width: auto;
                a{
                    float: none;
                }
            }
            a{
                width: fit-content;
                float: right;  
                img{
                    width: 600px;
                    height: auto;
                    
                    margin: 0;
                }
            }
        }
        
    }

}

@media(max-width: 740px){
    header{
        .container{
            a{
                img{
                    width: 350px;
                    
                }
            }
        }
    }
}

@media (max-width: 400px){
    header{
        .container{
            a{
                img{
                    width: 250px;
                }
            }
        }
    }

}